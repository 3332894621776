@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700&display=swap');

body {
    margin: 0;
    padding-right: 0 !important;
}

#root > div:first-child {
    overflow: auto;
}

/*Footer styles*/
.footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    height: 100px;
    padding: 0 11px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
}

.footer .footer__copy {
    display: flex;
    align-items: center;
    width: 50%;
}

.footer .footer__copy a {
    color: #000;
}

.footer .footer__copy .logo {
    width: 35%;
    height: auto;
    margin-right: 25px;
    max-width: 195px;
}

.footer .footer__banner {
    width: 40%;
    height: auto;
}

@media only screen and (max-width: 600px) {


    .footer {
        position: relative;
        justify-content: space-between;
        flex-direction: column;
        padding: 15px 20px;
        height: 130px;
    }

    .footer .footer__copy {
        width: 100%;
        text-align: center;
        font-size: 13px;
        justify-content: space-between;
    }

    .footer .footer__copy .logo {
        width: 20%;
    }

    .footer .footer__banner {
        width: 100%;
    }
}
/*End of footer styles*/

.user-info {
    position: fixed;
    right: 100px;
    top: 22px;
    z-index: 100;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    background: #f5f5f5;
    padding: 10px 50px;
}

@media only screen and (max-width: 960px) {

    .user-info {
        top: 0;
        right: 0;
        left: 0;
        z-index: 102;
        border-radius: 0;
        border-bottom: 1px solid #cd2653;
        padding: 3px;
    }
}

@media only screen and (min-width: 600px) {
    .layout {
        min-height: calc(100vh - 183px) !important;
    }
}

.logout {
    padding: 20px;
}

.contact-notification {
    width: 300px;
    border-radius: 20px;
    height: 90%;
    background-color: green;
}

.MuiSnackbar-anchorOriginBottomCenter {
    bottom: 120px !important;
}

.qr-wrapper {
    display: flex;
    flex-direction: column;
    margin: 30px 15px;
    width: 200px;
}

.qr-wrapper .qr-button {
    margin: 15px 0;
}

/* Custom style for userway widget */
body .uwy.userway_p1 .uai {
    top   : 20px !important;
    right : 540px !important;
}
